import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/sqlite";
import {Callout} from "../../components/alert";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires libsqlite version 3.x or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        A string containing a valid SQLite database file path
    </p>
);

const UserID = () => (<p>Not applicable for SQLite, leave empty</p>);

const Password = () => (<p>Not applicable for SQLite, leave empty</p>);

const OutputParamNotes = () => (
    <p>
        SQLAPI++ doesn't support SQLite functions and does not support returning output parameters.
    </p>
);

const Page = () => (
    <ServerGuide server="SQLite"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 sPassword={Password}
                 outputParamNotes={OutputParamNotes}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="SQLite"
                                                                      nativeCommand="sqlite3_interrupt"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="SQLite"
                                                                        cppHeader="sl3API.h"
                                                                        apiClass="sl3API"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="SQLite"
                                                                                handleClass="sl3ConnectionHandles"
                                                                                cppHeader="sl3API.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="SQLite"
                                                                          handleClass="sl3CommandHandles"
                                                                          cppHeader="sl3API.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="SQLite"/>}
    />
);

export default Page;
