import React from "react";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'SQLITE.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified SQLite library.`,
        valid:
            <>
                Any valid SQLite library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"sqlite3.dll"</code></li>
                <li>Linux - {' '}<code>"libsqlite3.so"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked SQLite client API functions when
                the library is compiled with {' '}<CodeBlend>SA_STATIC_SQLITE</CodeBlend>{' '} build option.
            </>
    },
    {
        name: 'SQLiteSkipInitialization',
        scope: 'api',
        description:
            <>
                Forces SQLAPI++ to skip {' '}<CodeBlend>sqlite3_initialize</CodeBlend>{' '} call when SQLite API loaded.
                Can be useful when {' '}<CodeBlend>sqlite3_config function</CodeBlend>{' '} should be called.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'BusyTimeout',
        scope: 'connection',
        description: `Sets a busy handler that sleeps for a specified amount of time when a table
                      is locked. For more information see SQLite documentation.`,
        valid:
            <>
                String representing busy timeout (in milliseconds)
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'SQLiteVFSName',
        scope: 'connection',
        description:
            <>
                Sets {' '}<CodeBlend>sqlite3_open_v2()</CodeBlend>{' '} interface VFS module name. For more information
                see SQLite documentation.
            </>,
        valid:
            <>
                Any string that represents VFS module name
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'SQLiteVFSFlags',
        scope: 'connection',
        description:
            <>
                Sets {' '}<CodeBlend>sqlite3_open_v2()</CodeBlend>{' '} interface VFS flags. For more information see
                SQLite documentation.
            </>,
        valid:
            <>
                Any numeric string that represents VFS flags
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'SQLiteDateTypes',
        scope: 'connection',
        description: `Sets a comma separated list of database field types that should be interpreted as a datetime fields.`,
        valid:
            <>
                Any suitable data type list
            </>,
        defaultValue:
            <code>
                "DATE,TIME,DATETIME,TIMESTAMP"
            </code>
    },
    {
        name: 'SQLiteDateValueType',
        scope: ['connection', 'command'],
        description:
            <>
                Sets a program type of the {' '}<CodeBlend>SA_dtDateTime</CodeBlend>{' '} parameters.
            </>,
        valid:
            <ul>
                <li>
                    <code>"TEXT"</code>{' '} - the datetime values are formatted into
                    {' '}<CodeBlend>"YYYY-MM-DD HH:MM:SS.SSS"</CodeBlend>{' '} strings
                </li>
                <li>
                    <code>"DOUBLE"</code>{' '} - the datetime vlaue is the Julian Day (JD) number expressed as a
                    floating point value
                </li>
            </ul>,
        defaultValue:
            <code>
                "TEXT"
            </code>
    },
    {
        name: 'SQLiteTransactionType',
        scope: 'connection',
        description: `Sets SQLite transaction type.`,
        valid:
            <ul>
                <li><code>"DEFERRED"</code> (default)</li>
                <li><code>"EXCLUSIVE"</code></li>
                <li><code>"IMMEDIATE"</code></li>
            </ul>,
        defaultValue:
            <>
                none
            </>
    }
];

export {
    options
};
